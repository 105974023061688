import { Alert, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import Loading from '../../components/Loading';
import { useContext } from 'react';
import ApplicationContext from '../../context/ApplicationContext';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from '../../utils/CommonFunctions';
import ModalityAndCareerContext from '../../context/ModalityAndCareerContext';

const EducationUniversityType = () =>
{
  const { applicationID } = useContext(ApplicationContext);
  const { modalityID, universityData, setUniversityData } = useContext(ModalityAndCareerContext);

  const [universityTypeList, setUniversityTypeList] = useState([]);
  const [errMessages, setErrMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [working, setWorking] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleUniversityTypeChange = (universityType) =>
  {
    setUniversityData({
      ...universityData,
      universityType: universityType,
    })
  };

  const transformFetchData = (data) =>
  {
    const resData = {
      universityId: data.university?.universityId ?? 0,
      universityType: data.university?.universityType ?? 0,
      firstPeriodYear: data.university?.firstPeriodYear ?? 0,
      firstPeriodCycle: data.university?.firstPeriodCycle ?? 0,
      lastPeriodYear: data.university?.lastPeriodYear ?? 0,
      lastPeriodCycle: data.university?.lastPeriodCycle ?? 0,
      notFound: data.university?.notFound ?? false,
      geolocation: data.university?.geolocation ?? '',
    }
    return resData;
  }

  const fetchData = async () =>
  {
    if (!applicationID) return;
    setIsLoading(true);
    await APIServices.fetchModalityAndCareerInfo(applicationID)
      .then((response) =>
      {
        const data = response.data.data;
        const universityTData = transformFetchData(data);
        setUniversityData(universityTData);
        const status = response.data.data?.status;
        setCanEdit(canUserEditInformation(status));
      })
      .catch((error) =>
      {
        // No se pudo obtener la info (aún no ha sido registrada)
      })
      .finally(() =>
      {
        setIsLoading(false);
      })
  }

  useEffect(() =>
  {
    const callServices = async () =>
    {
      setIsLoading(true);
      await APIServices.getUniversityTypeList().then((response) =>
      {
        setUniversityTypeList(response.data.data?.list ?? []);
      })
        .catch((error) =>
        {
          setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
          const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
          scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() =>
        {
          setIsLoading(false);
        })
    }
    callServices();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformSaveData = (d) =>
  {
    const data = {
      applicationId: applicationID,
      callModalityId: modalityID,
      university: {
        universityType: d.universityType ?? 0,
      }
    }
    return data;
  }

  const saveEducationInfo = (e) =>
  {
    const data = transformSaveData(universityData);
    setWorking(true);
    setErrMessages([]);

    APIServices.saveModalityAndCareerInfo(data)
      .then((response) =>
      {
        navigateTo();
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setWorking(false);
      })
  }

  const handleContinue = (e) =>
  {
    if (!canEdit)
    {
      navigateTo();
    }
    else
    {
      saveEducationInfo(e);
    }
  }

  const navigateTo = () =>
  {
    const isMainSelected = universityTypeList.find(item => item.universityType === universityData.universityType)?.isMain ?? false;
    if (isMainSelected)
      navigate(ROUTES.MODALITY_AND_CARREERS_UNIVERSITY);
    else
      navigate(ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_FOREIGN);
  }

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              {
                !canEdit ?
                  <>
                    <Grid item xs={12}>
                      <Alert role="alert" severity="warning">
                        <Typography>{t('common.already-confirmed')}</Typography>
                      </Alert>
                    </Grid>
                  </> : <></>
              }
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  {/* <Typography variant="h5" component="h5">
                    {t('modalities-and-careers.university-type.title')}
                  </Typography> */}
                  <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                    {t('modalities-and-careers.university-type.subtitle')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <RadioGroup
                      value={universityData.universityType}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {
                            universityTypeList?.map((type, index) =>
                            {
                              return (
                                <Fragment key={index}>
                                  <FormControlLabel
                                    className='radio-button'
                                    control={<Radio checked={universityData.universityType === type.universityType}
                                      disabled={!canEdit}
                                      onClick={() => { handleUniversityTypeChange(type.universityType) }}
                                    />}
                                    label={type.name}
                                  />
                                </Fragment>
                              )
                            })
                          }
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <ULButton
                      onClick={handleContinue}
                      working={working}
                      disabled={!(universityData?.universityType > 0)}
                    >
                      {t('buttons.continue')}
                    </ULButton>
                  </Grid>
                  <Grid item xs={12}
                  >
                    <ULButton
                      variant="outlined"
                      onClick={() => navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY)}
                      goBack={true}
                    >
                      {t('buttons.back')}
                    </ULButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </>
  );
}

export default EducationUniversityType;