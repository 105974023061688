import { Alert, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import Loading from '../../components/Loading';
import { useContext } from 'react';
import EducationContext from '../../context/EducationContext';
import ApplicationContext from '../../context/ApplicationContext';
import { MIN_DATE, handlePreventTypingChars } from '../../utils/Validations';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from '../../utils/CommonFunctions';

const EducationSchoolType = ({ grade = "fifth", reRenderLayout = () => {} }) =>
{
  const { applicationID, correcting } = useContext(ApplicationContext);
  const { fifthGradeSchool, setFifthGradeSchool, fourthGradeSchool, setFourthGradeSchool, thirdGradeSchool, setThirdGradeSchool } = useContext(EducationContext);
  const currentYear = new Date().getFullYear();
  const [schoolTypeList, setSchoolTypeList] = useState([]);
  const [errMessages, setErrMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errFifthYear, setErrFifthYear] = useState("");
  const [errFourthYear, setErrFourthYear] = useState("");
  const [errThirdYear, setErrThirdYear] = useState("");
  const [working, setWorking] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const checkValidFifthYear = (value) =>
  {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value > currentYear || value < MIN_DATE))
    {
      setErrFifthYear(t('education.fifth-year.fifth-year-information.invalid-year-err-msg'));
    }
    else
    {
      setErrFifthYear('');
    }
  }

  const handleFifthYearChange = (event) =>
  {
    setFifthGradeSchool({
      ...fifthGradeSchool,
      schoolYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    })
    if (event.target.value.length === 4 && fifthGradeSchool.sameSchool)
    {
      setFourthGradeSchool({
        ...fifthGradeSchool,
        schoolYear: parseInt(event.target.value) > 0 ? (parseInt(event.target.value) - 1) : 0,
      });
      setThirdGradeSchool({
        ...fifthGradeSchool,
        schoolYear: parseInt(event.target.value) > 0 ? (parseInt(event.target.value) - 2) : 0,
      });
    }
  };

  const handleFifthSchoolTypeChange = (schoolType) =>
  {
    setFifthGradeSchool({
      ...fifthGradeSchool,
      schoolType: schoolType,
    })
    setErrFifthYear('');
    setErrFourthYear('')
    setErrThirdYear('');
  };

  const checkValidFourthYear = (value) =>
  {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value >= fifthGradeSchool.schoolYear || value < MIN_DATE))
    {
      setErrFourthYear(t('education.fourth-year.fourth-year-information.invalid-year-err-msg', { date: MIN_DATE }));
    }
    else
    {
      setErrFourthYear('');
    }
  }

  const handleFourthYearChange = (event) =>
  {
    setFourthGradeSchool({
      ...fourthGradeSchool,
      schoolYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    })
    if (event.target.value.length === 4 && fourthGradeSchool.sameSchool)
    {
      setThirdGradeSchool({
        ...fifthGradeSchool,
        schoolYear: parseInt(event.target.value) > 0 ? (parseInt(event.target.value) - 1) : 0,
      });
    }
  };

  const handleFourthSchoolTypeChange = (schoolType) =>
  {
    setFourthGradeSchool({
      ...fourthGradeSchool,
      schoolType: schoolType,
    })
    setErrFourthYear('')
    setErrThirdYear('');
  };

  const checkValidThirdYear = (value) =>
  {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value >= fourthGradeSchool.schoolYear || value < MIN_DATE))
    {
      setErrThirdYear(t('education.third-year.third-year-information.invalid-year-err-msg', { date: MIN_DATE }));
    }
    else
    {
      setErrThirdYear('');
    }
  }

  const handleThirdYearChange = (event) =>
  {
    setThirdGradeSchool({
      ...thirdGradeSchool,
      schoolYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    })
  };

  const handleThirdSchoolTypeChange = (schoolType) =>
  {
    setThirdGradeSchool({
      ...thirdGradeSchool,
      schoolType: schoolType,
    })
    setErrThirdYear('');
  };

  React.useEffect(() =>
  {
    if (grade === "fifth" ? fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool)
    {
      if (grade === "fifth")
      {
        setFourthGradeSchool({
          ...fifthGradeSchool,
          schoolYear: fifthGradeSchool.schoolYear > 0 ? (fifthGradeSchool.schoolYear - 1) : 0,
        });
        setThirdGradeSchool({
          ...fifthGradeSchool,
          schoolYear: fifthGradeSchool.schoolYear > 0 ? (fifthGradeSchool.schoolYear - 2) : 0,
        });
        setErrFourthYear('');
      }
      if (grade === "fourth")
      {
        setThirdGradeSchool({
          ...fourthGradeSchool,
          schoolYear: fourthGradeSchool.schoolYear > 0 ? (fourthGradeSchool.schoolYear - 1) : 0,
        });
      }
      setErrThirdYear('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fifthGradeSchool.sameSchool, fourthGradeSchool.sameSchool])

  React.useEffect(() =>
  {
    if (grade === "fifth")
      checkValidFifthYear(fifthGradeSchool.schoolYear)
    if (grade === "fourth")
      checkValidFourthYear(fourthGradeSchool.schoolYear)
    if (grade === "third")
      checkValidThirdYear(thirdGradeSchool.schoolYear)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fifthGradeSchool, fourthGradeSchool, thirdGradeSchool])

  const handleSameSchoolChange = (e) =>
  {
    if (grade === "fifth")
    {
      setFifthGradeSchool({
        ...fifthGradeSchool,
        sameSchool: e.target.value === 'yes',
      });
    }
    if (grade === "fourth")
    {
      setFourthGradeSchool({
        ...fourthGradeSchool,
        sameSchool: e.target.value === 'yes',
      });
    }
  };

  const transformFetchData = (data) =>
  {
    const resData = {
      schoolId: data.school?.schoolId ?? 0,
      schoolType: data.schoolType ?? 0,
      schoolYear: data.schoolYear ?? 0,
      notFound: data.notFound ?? false,
      geolocation: data?.geolocation ?? '',
      applicantSchoolId: data.applicantSchoolId ?? 0,
      sameSchool: data.sameSchool ?? false,
    }
    return resData;
  }

  const fetchData = async () =>
  {
    if (!applicationID) return;
    setIsLoading(true);
    await APIServices.fetchEducationInfo(grade, applicationID)
      .then((response) =>
      {
        const data = response.data.data;
        const gradeSchool = transformFetchData(data);
        if (grade === "fifth")
        {
          setFifthGradeSchool(gradeSchool);
          if (gradeSchool.sameSchool)
          {
            const gradeSchoolFourth = { ...gradeSchool }
            gradeSchoolFourth.schoolYear = data.fourthGradeSchoolYear;
            setFourthGradeSchool(gradeSchoolFourth);
            const gradeSchoolThird = { ...gradeSchool }
            gradeSchoolThird.schoolYear = data.thirdGradeSchoolYear;
            setThirdGradeSchool(gradeSchoolThird);
          }
        }
        else if (grade === "fourth")
        {
          setFourthGradeSchool(gradeSchool);
          if (gradeSchool.sameSchool)
          {
            const gradeSchoolThird = { ...gradeSchool }
            gradeSchoolThird.schoolYear = data.thirdGradeSchoolYear;
            setThirdGradeSchool(gradeSchoolThird);
          }
        }
        else
          setThirdGradeSchool(gradeSchool);
        const status = response.data.data?.status;
        setCanEdit(canUserEditInformation(status));
      })
      .catch((error) =>
      {
        // No se pudo obtener la info (aún no ha sido registrada)
      })
      .finally(() =>
      {
        setIsLoading(false);
      })
  }

  useEffect(() =>
  {
    const callServices = async () =>
    {
      setIsLoading(true);
      await APIServices.getSchoolTypeList().then((response) =>
      {
        setSchoolTypeList(response.data.data?.list ?? []);
      })
        .catch((error) =>
        {
          setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
          const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
          scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() =>
        {
          setIsLoading(false);
        })
    }
    callServices();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformSaveData = (gradeSchool) =>
  {
    const data = {
      applicationId: applicationID,
      schoolId: gradeSchool.schoolId,
      schoolType: gradeSchool.schoolType,
      schoolYear: gradeSchool.schoolYear,
      notFound: gradeSchool.notFound,
      geolocation: gradeSchool?.geolocation ?? '',
      sameSchool: gradeSchool.sameSchool ?? false,
      fourthGradeSchoolYear: fourthGradeSchool.schoolYear,
      thirdGradeSchoolYear: thirdGradeSchool.schoolYear,
    }
    return data;
  }

  const saveEducationInfo = (e, isMainSelected) =>
  {
    const data = transformSaveData(grade === "fifth" ? fifthGradeSchool : grade === "fourth" ? fourthGradeSchool : thirdGradeSchool);
    if (data.schoolId === 0)
      delete data.schoolId
    setWorking(true);
    setErrMessages([]);

    APIServices.saveEducationInfo(grade, data)
      .then((response) =>
      {
        const data = response.data.data;
        if (grade === "fifth")
        {
          setFifthGradeSchool({
            ...fifthGradeSchool,
            applicantSchoolId: data?.applicantSchoolId ?? 0,
          })
          if (isMainSelected)
          {
            navigate(ROUTES.EDUCATION_FIFTH_LOCAL_SCHOOL);
          }
          else
          {
            if (fifthGradeSchool.sameSchool)
            {
              if (correcting)
                navigate(ROUTES.APPLICATION_SUMMARY);
              else
                navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
            }
            else
              navigate(ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE);
          }
        }
        if (grade === "fourth")
        {
          setFourthGradeSchool({
            ...fourthGradeSchool,
            applicantSchoolId: data?.applicantSchoolId ?? 0,
          })
          if (isMainSelected)
          {
            navigate(ROUTES.EDUCATION_FOURTH_LOCAL_SCHOOL);
          }
          else
          {
            if (fourthGradeSchool.sameSchool)
            {
              if (correcting)
                navigate(ROUTES.APPLICATION_SUMMARY);
              else
                navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
            }
            else
              navigate(ROUTES.EDUCATION_THIRD_SCHOOL_TYPE);
          }
        }
        if (grade === "third")
        {
          setThirdGradeSchool({
            ...thirdGradeSchool,
            applicantSchoolId: data?.applicantSchoolId ?? 0,
          })
          if (isMainSelected)
          {
            navigate(ROUTES.EDUCATION_THIRD_LOCAL_SCHOOL);
          }
          else
          {
            if (correcting)
              navigate(ROUTES.APPLICATION_SUMMARY);
            else
              navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
          }
        }
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setWorking(false);
      })
  }

  const navigateTo = (isMainSelected) =>
  {
    if (grade === "fifth")
    {
      if (isMainSelected)
      {
        navigate(ROUTES.EDUCATION_FIFTH_LOCAL_SCHOOL);
      }
      else
      {
        if (fifthGradeSchool.sameSchool)
        {
          if (correcting)
            navigate(ROUTES.APPLICATION_SUMMARY);
          else
            navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
        }
        else
          navigate(ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE);
      }
    }
    if (grade === "fourth")
    {
      if (isMainSelected)
      {
        navigate(ROUTES.EDUCATION_FOURTH_LOCAL_SCHOOL);
      }
      else
      {
        if (fourthGradeSchool.sameSchool)
        {
          if (correcting)
            navigate(ROUTES.APPLICATION_SUMMARY);
          else
            navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
        }
        else
          navigate(ROUTES.EDUCATION_THIRD_SCHOOL_TYPE);
      }
    }
    if (grade === "third")
    {
      if (isMainSelected)
      {
        navigate(ROUTES.EDUCATION_THIRD_LOCAL_SCHOOL);
      }
      else
      {
        if (correcting)
          navigate(ROUTES.APPLICATION_SUMMARY);
        else
          navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
      }
    }
  }

  const handleContinue = (e) =>
  {
    const isMainSelected = schoolTypeList.find(item => item.schoolType === (
      grade === "fifth" ? fifthGradeSchool.schoolType :
        grade === "fourth" ? fourthGradeSchool.schoolType :
          thirdGradeSchool.schoolType
    ))?.isMain ?? false;
    if (!canEdit)
    {
      navigateTo(isMainSelected);
    }
    else
    {
      saveEducationInfo(e, isMainSelected);
    }
  }

  useEffect(() =>
  {
    reRenderLayout && reRenderLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMainSelected = schoolTypeList.find(item => item.schoolType === (
    grade === "fifth" ? fifthGradeSchool.schoolType :
      grade === "fourth" ? fourthGradeSchool.schoolType :
        thirdGradeSchool.schoolType
  ))?.isMain ?? false;

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              {
                !canEdit ?
                  <>
                    <Grid item xs={12}>
                      <Alert role="alert" severity="warning">
                        <Typography>{t('common.already-confirmed')}</Typography>
                      </Alert>
                    </Grid>
                  </> : <></>
              }
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                    {
                      grade === "fifth" ? t('education.fifth-year.fifth-year-institution.title') :
                        grade === "fourth" ? t('education.fourth-year.fourth-year-institution.title') :
                          t('education.third-year.third-year-institution.title')
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h4">
                    {
                      grade === "fifth" ? t('education.fifth-year.fifth-year-institution.question') :
                        grade === "fourth" ? t('education.fourth-year.fourth-year-institution.question') :
                          t('education.third-year.third-year-institution.question')
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <RadioGroup
                      value={
                        grade === "fifth" ? fifthGradeSchool.schoolType :
                          grade === "fourth" ? fourthGradeSchool.schoolType :
                            thirdGradeSchool.schoolType
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {
                            schoolTypeList?.map((type, index) =>
                            {
                              return (
                                <Fragment key={index}>
                                  <FormControlLabel
                                    className='radio-button'
                                    control={<Radio checked={
                                      grade === "fifth" ? fifthGradeSchool.schoolType === type.schoolType :
                                        grade === "fourth" ? fourthGradeSchool.schoolType === type.schoolType :
                                          thirdGradeSchool.schoolType === type.schoolType
                                    }
                                      disabled={!canEdit}
                                      onClick={() =>
                                      {
                                        grade === "fifth" ? handleFifthSchoolTypeChange(type.schoolType) :
                                          grade === "fourth" ? handleFourthSchoolTypeChange(type.schoolType) :
                                            handleThirdSchoolTypeChange(type.schoolType)
                                      }}
                                    />}
                                    label={type.name}
                                  />
                                </Fragment>
                              )
                            })
                          }
                        </Grid>
                        {
                          !isMainSelected && (grade === "fifth" ? fifthGradeSchool.schoolType : grade === "fourth" ? fourthGradeSchool.schoolType : thirdGradeSchool.schoolType) ?
                            <>
                              <Grid item container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormControl fullWidth>
                                    <TextField inputProps={{ maxLength: 4 }}
                                      disabled={!canEdit}
                                      onKeyDown={handlePreventTypingChars}
                                      fullWidth
                                      value={
                                        grade === "fifth" ? (fifthGradeSchool.schoolYear !== 0 ? fifthGradeSchool.schoolYear : '') :
                                          grade === "fourth" ? (fourthGradeSchool.schoolYear !== 0 ? fourthGradeSchool.schoolYear : '') :
                                            (thirdGradeSchool.schoolYear !== 0 ? thirdGradeSchool.schoolYear : '')
                                      }
                                      label={
                                        grade === "fifth" ? t('education.fifth-year.fifth-year-institution.year-completed') :
                                          grade === "fourth" ? t('education.fourth-year.fourth-year-institution.year-completed') :
                                            t('education.third-year.third-year-institution.year-completed')
                                      }
                                      onChange={grade === "fifth" ? handleFifthYearChange : grade === "fourth" ? handleFourthYearChange : handleThirdYearChange}
                                    />
                                  </FormControl>
                                  {
                                    (grade === "fifth" ? errFifthYear.length : grade === "fourth" ? errFourthYear.length : errThirdYear.length) ?
                                      <>
                                        <Grid item>
                                          <div className="disclaimer validation-error">{grade === "fifth" ? errFifthYear : grade === "fourth" ? errFourthYear : errThirdYear}</div>
                                        </Grid>
                                      </> : <></>
                                  }
                                </Grid>
                                {
                                  (grade === "fifth" || grade === "fourth") ?
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                      >
                                        <FormControl fullWidth>
                                          <Typography variant="h5" component="h4">
                                            {grade === "fifth" ? t('education.fifth-year.fifth-year-institution.same-institute-question') : t('education.fourth-year.fourth-year-institution.same-institute-question')}
                                          </Typography>
                                          <RadioGroup
                                            value={grade === "fifth" ? (fifthGradeSchool.sameSchool ? 'yes' : 'no') : (fourthGradeSchool.sameSchool ? 'yes' : 'no')}
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item xs={6}>
                                                <FormControlLabel value='yes' control={<Radio onClick={handleSameSchoolChange} disabled={!canEdit} checked={grade === "fifth" ? fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool} />} label={grade === "fifth" ? t('education.fifth-year.fifth-year-institution.same-institute-options.yes') : t('education.fourth-year.fourth-year-institution.same-institute-options.yes')} />
                                              </Grid>
                                              <Grid item xs={6}>
                                                <FormControlLabel value='no' control={<Radio onClick={handleSameSchoolChange} disabled={!canEdit} checked={grade === "fifth" ? !fifthGradeSchool.sameSchool : !fourthGradeSchool.sameSchool} />} label={grade === "fifth" ? t('education.fifth-year.fifth-year-institution.same-institute-options.no') : t('education.fourth-year.fourth-year-institution.same-institute-options.no')} />
                                              </Grid>
                                            </Grid>
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                      {(grade === "fifth" ? fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool) ?
                                        <>
                                          <Grid item container spacing={2}>
                                            {
                                              grade === "fifth" ?
                                                <>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                  >
                                                    <FormControl fullWidth>
                                                      <TextField inputProps={{ maxLength: 4 }}
                                                        disabled={!canEdit}
                                                        onKeyDown={handlePreventTypingChars}
                                                        fullWidth
                                                        value={fourthGradeSchool.schoolYear !== 0 ? fourthGradeSchool.schoolYear : ''}
                                                        label={t('education.fifth-year.fifth-year-institution.year-completed-four-high-school')}
                                                        onChange={handleFourthYearChange}
                                                      />
                                                    </FormControl>
                                                    {
                                                      errFourthYear.length ?
                                                        <>
                                                          <Grid item>
                                                            <div className="disclaimer validation-error">{errFourthYear}</div>
                                                          </Grid>
                                                        </> : <></>
                                                    }
                                                  </Grid>
                                                </> : <></>
                                            }
                                            <Grid
                                              item
                                              xs={12}
                                            >
                                              <FormControl fullWidth>
                                                <TextField inputProps={{ maxLength: 4 }}
                                                  disabled={!canEdit}
                                                  onKeyDown={handlePreventTypingChars}
                                                  fullWidth
                                                  value={thirdGradeSchool.schoolYear !== 0 ? thirdGradeSchool.schoolYear : ''}
                                                  label={grade === "fifth" ? t('education.fifth-year.fifth-year-institution.year-completed-three-high-school') : t('education.fourth-year.fourth-year-institution.year-completed-three-high-school')}
                                                  onChange={handleThirdYearChange}
                                                />
                                              </FormControl>
                                              {
                                                errThirdYear.length ?
                                                  <>
                                                    <Grid item>
                                                      <div className="disclaimer validation-error">{errThirdYear}</div>
                                                    </Grid>
                                                  </> : <></>
                                              }
                                            </Grid>
                                          </Grid>
                                        </> : <></>}
                                    </> : <></>
                                }
                              </Grid>
                            </> : <></>
                        }
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <ULButton
                      onClick={handleContinue}
                      working={working}
                      disabled={
                        grade === "fifth" ?
                          (
                            !isMainSelected &&
                            (!(fifthGradeSchool.schoolYear.toString().length && fifthGradeSchool.schoolYear > MIN_DATE && fifthGradeSchool.schoolYear <= currentYear)
                              || !(
                                !fifthGradeSchool.sameSchool ||
                                (fifthGradeSchool.sameSchool && fourthGradeSchool.schoolYear.toString().length && thirdGradeSchool.schoolYear.toString().length && fifthGradeSchool.schoolYear > fourthGradeSchool.schoolYear && fourthGradeSchool.schoolYear > thirdGradeSchool.schoolYear && thirdGradeSchool.schoolYear > MIN_DATE)
                              )
                            )
                          ) :
                          grade === "fourth" ?
                            (
                              !isMainSelected && (
                                !(fourthGradeSchool.schoolYear.toString().length === 4 && fourthGradeSchool.schoolYear > MIN_DATE && fourthGradeSchool.schoolYear < fifthGradeSchool.schoolYear) ||
                                !(!fourthGradeSchool.sameSchool || (fourthGradeSchool.sameSchool && thirdGradeSchool.schoolYear.toString().length === 4 && fourthGradeSchool.schoolYear > thirdGradeSchool.schoolYear && thirdGradeSchool.schoolYear > MIN_DATE))
                              )
                            ) :
                            (
                              !isMainSelected && (
                                !thirdGradeSchool.schoolType ||
                                !(thirdGradeSchool.schoolYear.toString().length === 4 && thirdGradeSchool.schoolYear > MIN_DATE && thirdGradeSchool.schoolYear < fourthGradeSchool.schoolYear)
                              )
                            )
                      }
                    >
                      {
                        grade === "fifth" ? t('buttons.continue') :
                          grade === "fourth" ? t('buttons.continue') :
                            t('buttons.continue')
                      }
                    </ULButton>
                  </Grid>
                  <Grid item xs={12}
                  >
                    <ULButton
                      variant="outlined"
                      onClick={() => navigate(
                        grade === "fifth" ? (ROUTES.STUDENT_PERSONAL_DATA) :
                          grade === "fourth" ? ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE :
                            ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE
                      )}
                      goBack={true}
                    >
                      {t('buttons.back')}
                    </ULButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </>
  );
}

export default EducationSchoolType;