import * as React from 'react';
import { Alert, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import StudentPersonalDataDisability from "./StudentPersonalDataDisability";
import { FormControl, Radio, RadioGroup } from '@mui/material';
import { useContext } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from '../../context/ApplicationContext';
import { useEffect } from 'react';
import Loading from '../../components/Loading';
import { handlePreventInvalidPhoneNumbers, isValidEmail } from '../../utils/Validations';
import PersonalInformationContext from '../../context/PersonalInformationContext';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from '../../utils/CommonFunctions';

const StudentPersonalData = (props) =>
{
  const { applicationID, verificationCodeValidated, correcting } = useContext(ApplicationContext);
  const { name, setName,
    firstLastName, setFirstLastName,
    secondLastName, setSecondLastName,
    cellphone, setCellphone,
    email, setEmail,
    emailValidation, setEmailValidation,
    readTerms, setReadTerms,
    acceptTerms, setAcceptTerms,
    hasDisability, setHasDisability,
    hasConadis, setHasConadis,
    disabilityDescription, setDisabilityDescription,
    conadisDocument, setConadisDocument } = useContext(PersonalInformationContext);
  const [working, setWorking] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [invalidEmailFeedback, setInvalidEmailFeedback] = useState(false);
  const [condition, setCondition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessages, setErrMessages] = useState([]);

  const handleDisabilityChange = (event) =>
  {
    setHasDisability(event.target.value);
  };

  const conditionButton = () =>
  {
    const result = readTerms && acceptTerms && name?.length > 0 && firstLastName?.length > 0 && secondLastName?.length > 0 && cellphone?.length >= 9 && email?.length > 0 && emailValidation?.length > 0 && email === emailValidation && (hasDisability === "si" ? disabilityDescription.length : true);
    return result;
  }

  const evaluateStudent = (e) =>
  {
    if (!canEdit)
    {
      if (correcting)
        navigate(ROUTES.APPLICATION_SUMMARY);
      else
        navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE);
    }
    else
    {
      if (!conditionButton) return;
      if (!isValidEmail(email))
      {
        setInvalidEmailFeedback(true);
        return;
      }
      const data = {
        "applicationId": applicationID,
        "name": name,
        "firstSurname": firstLastName,
        "secondSurname": secondLastName,
        "cellPhone": cellphone,
        "email": email,
        "hasDisability": hasDisability === "si",
        "hasConadis": hasConadis === "si",
        "conadisDocument": conadisDocument,
        "disabilityDescription": disabilityDescription,
        "declarationAccepted": acceptTerms,
      }
      setWorking(true);
      setErrMessages([]);
      APIServices.saveApplicantInfo(data)
        .then((response) =>
        {
          if (verificationCodeValidated)
          {
            if (correcting)
              navigate(ROUTES.APPLICATION_SUMMARY);
            else
              navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE);
          }
          else
          {
            navigate(ROUTES.STUDENT_CHOOSE_VERIFICATION_TYPE, { state: { cellphone: cellphone, email: email } });
          }
        })
        .catch((error) =>
        {
          setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
          const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
          scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() =>
        {
          setWorking(false);
        })
    }
  }

  const readTermsAndConditions = () =>
  {
    setReadTerms(!readTerms)
  }

  const acceptTermsAndConditions = () =>
  {
    setAcceptTerms(!acceptTerms)
  }

  const handleChangeName = (e) =>
  {
    setName(e.target.value.toUpperCase())
  }

  const handleChangeFirstLastName = (e) =>
  {
    setFirstLastName(e.target.value.toUpperCase())
  }

  const handleChangeSecondLastName = (e) =>
  {
    setSecondLastName(e.target.value.toUpperCase())
  }

  const handleChangeCellphone = (e) =>
  {
    setCellphone(e.target.value)
  }

  const handleChangeEmail = (e) =>
  {
    invalidEmailFeedback && setInvalidEmailFeedback(false);
    setEmail(e.target.value.toLowerCase());
  }

  const handleChangeEmailValidation = (e) =>
  {
    setEmailValidation(e.target.value.toLowerCase())
  }

  const handleChangeDisabilityDescription = (value) =>
  {
    setDisabilityDescription(value.toUpperCase())
  }

  const handleChangeHasConadis = (value) =>
  {
    setHasConadis(value)
  }
  const handleChangeConadisDocument = (value) =>
  {
    setConadisDocument(value)
  }

  const preventClipboard = (e) =>
  {
    e.preventDefault();
  }

  const fetchData = async () =>
  {
    if (!applicationID) return;
    setIsLoading(true);
    await APIServices.fetchApplicantInfo(applicationID)
      .then((response) =>
      {
        const data = response.data.data;
        if (data)
        {
          setName(data.name ?? '');
          setFirstLastName(data.firstSurname ?? '');
          setSecondLastName(data.secondSurname ?? '');
          setCellphone(data.cellPhone ?? '');
          setEmail(data.email ?? '');
          setEmailValidation(data.email ?? '');
          setHasDisability(data.hasDisability ? "si" : "no");
          setHasConadis(data.hasConadis ? "si" : "no")
          setConadisDocument(data.conadisDocument ?? '')
          setDisabilityDescription(data.disabilityDescription ?? '');
          setReadTerms(data.declarationAccepted ?? false);
          setAcceptTerms(data.declarationAccepted ?? false);
        }
        const status = response.data.data?.status;
        setCanEdit(canUserEditInformation(status));
      })
      .catch((error) =>
      {
        // No se pudo obtener la info (aún no ha sido registrada)
      })
      .finally(() =>
      {
        setIsLoading(false);
      })
  }

  useEffect(() =>
  {
    setCondition(conditionButton())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, name, firstLastName, secondLastName, cellphone, emailValidation, readTerms, acceptTerms, hasConadis, hasDisability, disabilityDescription, conadisDocument]);

  useEffect(() =>
  {
    const callServices = async () =>
    {
      await fetchData();
    }
    callServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() =>
  {
    props.reRenderLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ? <Grid item xs={12}>
                  <Alert role="alert" severity="error">
                    {
                      errMessages.map((err, index) => (
                        <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                      ))
                    }
                  </Alert>
                </Grid> : <></>
              }
              {
                !canEdit ?
                  <>
                    <Grid item xs={12}>
                      <Alert role="alert" severity="warning">
                        <Typography>{t('common.already-confirmed')}</Typography>
                      </Alert>
                    </Grid>
                  </> : <></>
              }
              <Grid item xs={12}>
                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                  {t('personal-data.title')}
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeName(e)} value={name} fullWidth label={t('personal-data.names-placeholder')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeFirstLastName(e)} value={firstLastName} fullWidth label={t('personal-data.first-lastname')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeSecondLastName(e)} value={secondLastName} fullWidth label={t('personal-data.second-lastname')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeCellphone(e)} value={cellphone} fullWidth label={t('personal-data.phone-number')} onCopy={preventClipboard} onKeyDown={handlePreventInvalidPhoneNumbers} onPaste={preventClipboard} />
                </Grid>

                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeEmail(e)} value={email} fullWidth label={t('personal-data.email')} onCopy={preventClipboard} onPaste={preventClipboard} />
                </Grid>
                {
                  invalidEmailFeedback ?
                    <>
                      <Grid item xs={12}>
                        <div className="disclaimer validation-error"> {t('personal-data.email-validation-error')} </div>
                      </Grid>
                    </> : <></>
                }
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeEmailValidation(e)} value={emailValidation} fullWidth label={t('personal-data.email-reenter')} onCopy={preventClipboard} onPaste={preventClipboard} />
                </Grid>

                <Grid item xs={12}>
                  <div className="disclaimer"> {t('personal-data.email-sub-label')} </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h4">
                    {t('personal-data.personal-data-disability.has-disability-label')}
                  </Typography>
                  <FormControl fullWidth>
                    <RadioGroup
                      value={hasDisability}
                    >
                      <Grid container spacing={2} >
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="si"
                            onChange={handleDisabilityChange}
                            disabled={!canEdit}
                            control={<Radio checked={hasDisability === 'si'} />}
                            label={t('education.fifth-year.fifth-year-information.same-institute-options.yes')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="no"
                            onChange={handleDisabilityChange}
                            disabled={!canEdit}
                            control={<Radio checked={hasDisability === 'no'} />}
                            label={t('education.fifth-year.fifth-year-information.same-institute-options.no')}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                  {
                    hasDisability === 'si' ?
                      <Grid item xs={12}>
                        <StudentPersonalDataDisability
                          hasConadis={hasConadis}
                          handleChangeHasConadis={handleChangeHasConadis}
                          conadisDocument={conadisDocument}
                          handleChangeConadisDocument={handleChangeConadisDocument}
                          disabilityDescription={disabilityDescription}
                          handleChangeDisabilityDescription={handleChangeDisabilityDescription}
                          canEdit={canEdit}
                        ></StudentPersonalDataDisability>
                      </Grid> : <></>
                  }
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel disabled={!canEdit} control={<Checkbox onChange={readTermsAndConditions} checked={readTerms} />} label={t('personal-data.terms-conditions-part-1') + t('personal-data.terms-conditions-part-2')} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel disabled={!canEdit} control={<Checkbox onChange={acceptTermsAndConditions} checked={acceptTerms} />} label={t('personal-data.disability-text')} />
                </Grid>
                <Grid item xs={12} >
                  <ULButton working={working} onClick={evaluateStudent} disabled={!condition}>{t('buttons.continue')}</ULButton>
                </Grid>
                <Grid item xs={12} >
                  <ULButton variant="outlined" onClick={() => navigate(ROUTES.STUDENT_IDENTIFICATION)} goBack={true}>{t('buttons.back')}</ULButton>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </>
  )
}

export default StudentPersonalData;