import { Alert, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import Loading from '../../components/Loading';
import { useContext } from 'react';
import EducationContext from '../../context/EducationContext';
import ApplicationContext from '../../context/ApplicationContext';
import { MIN_DATE, handlePreventTypingChars } from '../../utils/Validations';
import FileUploadManager from '../../components/FileUploadManager';
import { v4 as uuidv4 } from 'uuid';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from '../../utils/CommonFunctions';

const EducationSchoolNotFound = ({ grade = "fifth" }) =>
{
  const { applicationID, correcting } = useContext(ApplicationContext);
  const { fifthGradeSchool, setFifthGradeSchool, fourthGradeSchool, setFourthGradeSchool, thirdGradeSchool, setThirdGradeSchool } = useContext(EducationContext);
  const currentYear = new Date().getFullYear();
  const [errMessages, setErrMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errFifthYear, setErrFifthYear] = useState("");
  const [errFourthYear, setErrFourthYear] = useState("");
  const [errThirdYear, setErrThirdYear] = useState("");
  const [files, setFiles] = React.useState([]);
  const [working, setWorking] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [canEditFiles, setCanEditFiles] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const uploadFile = (file, tempId) =>
  {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) =>
      {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.progress = percentCompleted;
          }
          return file;
        })));
      },
    }
    const applicantSchoolId = (grade === "fifth" ? fifthGradeSchool.applicantSchoolId : grade === "fourth" ? fourthGradeSchool.applicantSchoolId : thirdGradeSchool.applicantSchoolId);
    const data = new FormData();
    data.append('file', file);
    data.append('applicationId', applicationID);
    data.append('applicantSchoolId', applicantSchoolId);

    APIServices.uploadFileNF(data, options)
      .then((response) =>
      {
        const data = response.data.data;
        setFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.uploaded = true;
            file.uuid = data.uuid;
            file.sizeText = data.sizeText;
          }
          return file;
        })));
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.errMessages = errMessages;
          }
          return file;
        })));
      })
  }

  const isAlreadyUploaded = (accepted) =>
  {
    return files.some(file =>
      file.name === accepted.name
    );
  }

  const handleSetFiles = (files) =>
  {
    setFiles(files.map((file) =>
    {
      if (!file.tempId && !file.uploaded && !file.progress)
      {
        if (!isAlreadyUploaded(file))
        {
          const uuid = uuidv4();
          uploadFile(file, uuid);
          file.tempId = uuid;
          file.uploaded = false;
          file.progress = 0;
          file.canEdit = true;
        }
        else
        {
          const errMessages = [{ code: "", message: t('education.common.file-already-exists-error', { filename: file.name }) }]
          file.already = true;
          file.errMessages = errMessages;
        }
      }
      return file;
    }));
  }

  const handleDeleteFile = (file) =>
  {
    if (file.already)
    {
      setFiles((prevFiles) => (prevFiles.filter((f) => f !== file)));
    }
    else
    {
      const applicantSchoolId = (grade === "fifth" ? fifthGradeSchool.applicantSchoolId : grade === "fourth" ? fourthGradeSchool.applicantSchoolId : thirdGradeSchool.applicantSchoolId);
      APIServices.deleteFileNF({
        applicationId: applicationID,
        applicantSchoolId: applicantSchoolId,
        uuid: file.uuid,
      })
        .then((response) =>
        {
          setFiles((prevFiles) => (prevFiles.filter((f) => f !== file)));
        })
        .catch((error) =>
        {
          const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
          setFiles((prevFiles) => (prevFiles.map((pfile) =>
          {
            if (pfile.uuid === file.uuid)
            {
              pfile.errMessages = errMessages;
            }
            return pfile;
          })));
        })
    }
  }

  const handleDownloadFile = (file) =>
  {
    const applicantSchoolId = (grade === "fifth" ? fifthGradeSchool.applicantSchoolId : grade === "fourth" ? fourthGradeSchool.applicantSchoolId : thirdGradeSchool.applicantSchoolId);
    APIServices.downloadFileNF(applicationID, applicantSchoolId, file.uuid)
      .then((response) =>
      {
        const imageContentType = response.headers['content-type'];
        const imageFilename = response.headers.ulima_filename;
        const imageBinary = new Blob([response.data], { type: imageContentType });
        const imageUrl = URL.createObjectURL(imageBinary);
        const dlink = document.createElement("a");
        dlink.href = imageUrl;
        dlink.download = imageFilename;
        dlink.target = "_blank";
        dlink.style.display = "none";
        document.body.appendChild(dlink);
        dlink.click();
        URL.revokeObjectURL(imageUrl);
        document.body.removeChild(dlink);
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setFiles((prevFiles) => (prevFiles.map((pfile) =>
        {
          if (pfile.uuid === file.uuid)
          {
            pfile.errMessages = errMessages;
          }
          return pfile;
        })));
      })
  }

  const listDocuments = (applicantSchoolId) =>
  {
    // const applicantSchoolId = (grade === "fifth" ? fifthGradeSchool.applicantSchoolId : grade === "fourth" ? fourthGradeSchool.applicantSchoolId : thirdGradeSchool.applicantSchoolId);
    APIServices.listFilesNF(applicationID, applicantSchoolId)
      .then((response) =>
      {
        const files = response.data.data?.list;
        if (files?.length)
        {
          setFiles(files.map(file =>
          {
            const { fileName, ...rest } = file;
            return { ...rest, name: fileName, uploaded: fileName.length ? true : false };
          }));
        }
        const status = response.data.data?.status;
        setCanEditFiles(canUserEditInformation(status));
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
  }

  const checkValidFifthYear = (value) =>
  {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value > currentYear || value < MIN_DATE))
    {
      setErrFifthYear(t('education.fifth-year.fifth-year-information.invalid-year-err-msg'));
    }
    else
    {
      setErrFifthYear('');
    }
  }

  const handleFifthYearChange = (event) =>
  {
    setFifthGradeSchool({
      ...fifthGradeSchool,
      schoolYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    })
    if (event.target.value.length === 4 && fifthGradeSchool.sameSchool)
    {
      setFourthGradeSchool({
        ...fifthGradeSchool,
        schoolYear: parseInt(event.target.value) > 0 ? (parseInt(event.target.value) - 1) : 0,
      });
      setThirdGradeSchool({
        ...fifthGradeSchool,
        schoolYear: parseInt(event.target.value) > 0 ? (parseInt(event.target.value) - 2) : 0,
      });
    }
  };

  const checkValidFourthYear = (value) =>
  {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value >= fifthGradeSchool.schoolYear || value < MIN_DATE))
    {
      setErrFourthYear(t('education.fourth-year.fourth-year-information.invalid-year-err-msg', { date: MIN_DATE }));
    }
    else
    {
      setErrFourthYear('');
    }
  }

  const handleFourthYearChange = (event) =>
  {
    setFourthGradeSchool({
      ...fourthGradeSchool,
      schoolYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    })
    if (event.target.value.length === 4 && fourthGradeSchool.sameSchool)
    {
      setThirdGradeSchool({
        ...fifthGradeSchool,
        schoolYear: parseInt(event.target.value) > 0 ? (parseInt(event.target.value) - 1) : 0,
      });
    }
  };

  const checkValidThirdYear = (value) =>
  {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value >= fourthGradeSchool.schoolYear || value < MIN_DATE))
    {
      setErrThirdYear(t('education.third-year.third-year-information.invalid-year-err-msg', { date: MIN_DATE }));
    }
    else
    {
      setErrThirdYear('');
    }
  }

  const handleThirdYearChange = (event) =>
  {
    setThirdGradeSchool({
      ...thirdGradeSchool,
      schoolYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    })
  };

  React.useEffect(() =>
  {
    if (grade === "fifth")
      checkValidFifthYear(fifthGradeSchool.schoolYear)
    if (grade === "fourth")
      checkValidFourthYear(fourthGradeSchool.schoolYear)
    if (grade === "third")
      checkValidThirdYear(thirdGradeSchool.schoolYear)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fifthGradeSchool, fourthGradeSchool, thirdGradeSchool])

  React.useEffect(() =>
  {
    if (grade === "fifth" ? fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool)
    {
      if (grade === "fifth")
      {
        setFourthGradeSchool({
          ...fifthGradeSchool,
          schoolYear: fifthGradeSchool.schoolYear > 0 ? (fifthGradeSchool.schoolYear - 1) : 0,
        });
        setThirdGradeSchool({
          ...fifthGradeSchool,
          schoolYear: fifthGradeSchool.schoolYear > 0 ? (fifthGradeSchool.schoolYear - 2) : 0,
        });
        setErrFourthYear('');
      }
      if (grade === "fourth")
      {
        setThirdGradeSchool({
          ...fourthGradeSchool,
          schoolYear: fourthGradeSchool.schoolYear > 0 ? (fourthGradeSchool.schoolYear - 1) : 0,
        });
      }
      setErrThirdYear('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fifthGradeSchool.sameSchool, fourthGradeSchool.sameSchool])

  const handleSameSchoolChange = (e) =>
  {
    if (grade === "fifth")
    {
      setFifthGradeSchool({
        ...fifthGradeSchool,
        sameSchool: e.target.value === 'yes',
      });
    }
    if (grade === "fourth")
    {
      setFourthGradeSchool({
        ...fourthGradeSchool,
        sameSchool: e.target.value === 'yes',
      });
    }
  };

  const transformFetchData = (data) =>
  {
    const resData = {
      schoolId: data.school?.schoolId ?? 0,
      schoolType: data.schoolType ?? 0,
      schoolYear: data.schoolYear ?? 0,
      notFound: data.notFound ?? false,
      geolocation: data.geolocation ?? '',
      applicantSchoolId: data.applicantSchoolId ?? 0,
      sameSchool: data.sameSchool ?? false,
    }
    return resData;
  }

  const fetchData = async () =>
  {
    if (!applicationID) return;
    setIsLoading(true);
    await APIServices.fetchEducationInfo(grade, applicationID)
      .then((response) =>
      {
        const data = response.data.data;
        const gradeSchool = transformFetchData(data);
        if (grade === "fifth")
        {
          setFifthGradeSchool(gradeSchool);
          if (gradeSchool.sameSchool)
          {
            const gradeSchoolFourth = { ...gradeSchool }
            gradeSchoolFourth.schoolYear = data.fourthGradeSchoolYear;
            setFourthGradeSchool(gradeSchoolFourth);
            const gradeSchoolThird = { ...gradeSchool }
            gradeSchoolThird.schoolYear = data.thirdGradeSchoolYear;
            setThirdGradeSchool(gradeSchoolThird);
          }
        }
        else if (grade === "fourth")
        {
          setFourthGradeSchool(gradeSchool);
          if (gradeSchool.sameSchool)
          {
            const gradeSchoolThird = { ...gradeSchool }
            gradeSchoolThird.schoolYear = data.thirdGradeSchoolYear;
            setThirdGradeSchool(gradeSchoolThird);
          }
        }
        else
          setThirdGradeSchool(gradeSchool);
        listDocuments(data.applicantSchoolId);
        const status = response.data.data?.status;
        setCanEdit(canUserEditInformation(status));
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setIsLoading(false);
      })
  }

  useEffect(() =>
  {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformSaveData = (gradeSchool) =>
  {
    const data = {
      applicationId: applicationID,
      schoolId: gradeSchool.schoolId,
      schoolType: gradeSchool.schoolType,
      schoolYear: gradeSchool.schoolYear,
      notFound: gradeSchool.notFound,
      geolocation: gradeSchool.geolocation,
      sameSchool: gradeSchool.sameSchool ?? false,
      fourthGradeSchoolYear: fourthGradeSchool.schoolYear,
      thirdGradeSchoolYear: thirdGradeSchool.schoolYear,
    }
    if (grade === "fifth")
    {
      data.fourthGradeSchoolYear = fourthGradeSchool.schoolYear;
      data.thirdGradeSchoolYear = thirdGradeSchool.schoolYear;
    }
    if (grade === "fourth")
    {
      data.thirdGradeSchoolYear = thirdGradeSchool.schoolYear;
    }
    return data;
  }

  const saveEducationInfo = (e) =>
  {
    const data = transformSaveData(grade === "fifth" ? fifthGradeSchool : grade === "fourth" ? fourthGradeSchool : thirdGradeSchool);
    if (data.schoolId === 0)
      delete data.schoolId
    setWorking(true);
    setErrMessages([]);

    APIServices.saveEducationInfo(grade, data)
      .then((response) =>
      {
        const data = response.data.data;
        if (grade === "fifth")
        {
          setFifthGradeSchool({
            ...fifthGradeSchool,
            applicantSchoolId: data?.applicantSchoolId ?? 0,
          })
          if (fifthGradeSchool.sameSchool)
          {
            if (correcting)
              navigate(ROUTES.APPLICATION_SUMMARY);
            else
              navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
          }
          else
            navigate(ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE);
        }
        if (grade === "fourth")
        {
          setFourthGradeSchool({
            ...fourthGradeSchool,
            applicantSchoolId: data?.applicantSchoolId ?? 0,
          })
          if (fourthGradeSchool.sameSchool)
          {
            if (correcting)
              navigate(ROUTES.APPLICATION_SUMMARY);
            else
              navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
          }
          else
            navigate(ROUTES.EDUCATION_THIRD_SCHOOL_TYPE);
        }
        if (grade === "third")
        {
          setThirdGradeSchool({
            ...thirdGradeSchool,
            applicantSchoolId: data?.applicantSchoolId ?? 0,
          })
          if (correcting)
            navigate(ROUTES.APPLICATION_SUMMARY);
          else
            navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
        }
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() => {
        setWorking(false);
      })
  }

  const navigateTo = () =>
  {
    if (grade === "fifth")
    {
      if (fifthGradeSchool.sameSchool)
      {
        if (correcting)
          navigate(ROUTES.APPLICATION_SUMMARY);
        else
          navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
      }
      else
        navigate(ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE);
    }
    if (grade === "fourth")
    {
      if (fourthGradeSchool.sameSchool)
      {
        if (correcting)
          navigate(ROUTES.APPLICATION_SUMMARY);
        else
          navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
      }
      else
        navigate(ROUTES.EDUCATION_THIRD_SCHOOL_TYPE);
    }
    if (grade === "third")
    {
      if (correcting)
        navigate(ROUTES.APPLICATION_SUMMARY);
      else
        navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
    }
  }

  const handleContinue = (e) =>
  {
    if (!canEdit)
    {
      navigateTo();
    }
    else
    {
      saveEducationInfo(e);
    }
  }

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              {
                !canEdit ?
                  <>
                    <Grid item xs={12}>
                      <Alert role="alert" severity="warning">
                        <Typography>{t('common.already-confirmed')}</Typography>
                      </Alert>
                    </Grid>
                  </> : <></>
              }
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                    {t('education.institution-not-found.title')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <ul style={{ margin: 0 }}>
                    <li>{t('education.institution-not-found.notepad')}</li>
                    <li>{t('education.institution-not-found.certificate-studies')}</li>
                    <li>{t('education.institution-not-found.voucher')}</li>
                    <li>{t('education.institution-not-found.other-document')}</li>
                  </ul>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FileUploadManager
                    canEdit={canEditFiles & canEdit}
                    multiple={true}
                    files={files}
                    setFiles={handleSetFiles}
                    deleteFile={handleDeleteFile}
                    downloadFile={handleDownloadFile}
                    title={''}
                    description={''}
                    fileType={
                      {
                        'application/pdf': [],
                        'image/jpeg': [],
                        'application/msword': [],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                      }
                    }
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <RadioGroup
                      value={
                        grade === "fifth" ? fifthGradeSchool.schoolType :
                          grade === "fourth" ? fourthGradeSchool.schoolType :
                            thirdGradeSchool.schoolType
                      }
                    >
                      <Grid container spacing={2}>
                        {
                          (grade === "fifth" ? fifthGradeSchool.schoolType : grade === "fourth" ? fourthGradeSchool.schoolType : thirdGradeSchool.schoolType) ?
                            <>
                              <Grid item container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormControl fullWidth>
                                    <TextField inputProps={{ maxLength: 4 }}
                                      disabled={!canEdit}
                                      onKeyDown={handlePreventTypingChars}
                                      fullWidth
                                      value={
                                        grade === "fifth" ? (fifthGradeSchool.schoolYear !== 0 ? fifthGradeSchool.schoolYear : '') :
                                          grade === "fourth" ? (fourthGradeSchool.schoolYear !== 0 ? fourthGradeSchool.schoolYear : '') :
                                            (thirdGradeSchool.schoolYear !== 0 ? thirdGradeSchool.schoolYear : '')
                                      }
                                      label={
                                        grade === "fifth" ? t('education.fifth-year.fifth-year-institution.year-completed') :
                                          grade === "fourth" ? t('education.fourth-year.fourth-year-institution.year-completed') :
                                            t('education.third-year.third-year-institution.year-completed')
                                      }
                                      onChange={grade === "fifth" ? handleFifthYearChange : grade === "fourth" ? handleFourthYearChange : handleThirdYearChange}
                                    />
                                  </FormControl>
                                  {
                                    (grade === "fifth" ? errFifthYear.length : grade === "fourth" ? errFourthYear.length : errThirdYear.length) ?
                                      <>
                                        <Grid item>
                                          <div className="disclaimer validation-error">{grade === "fifth" ? errFifthYear : grade === "fourth" ? errFourthYear : errThirdYear}</div>
                                        </Grid>
                                      </> : <></>
                                  }
                                </Grid>
                                {
                                  (grade === "fifth" || grade === "fourth") ?
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                      >
                                        <FormControl fullWidth>
                                          <Typography variant="h5" component="h4">
                                            {grade === "fifth" ? t('education.fifth-year.fifth-year-institution.same-institute-question') : t('education.fourth-year.fourth-year-institution.same-institute-question')}
                                          </Typography>
                                          <RadioGroup
                                            value={grade === "fifth" ? (fifthGradeSchool.sameSchool ? 'yes' : 'no') : (fourthGradeSchool.sameSchool ? 'yes' : 'no')}
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item xs={6}>
                                                <FormControlLabel value='yes' control={<Radio onClick={handleSameSchoolChange} disabled={!canEdit} checked={grade === "fifth" ? fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool} />} label={grade === "fifth" ? t('education.fifth-year.fifth-year-institution.same-institute-options.yes') : t('education.fourth-year.fourth-year-institution.same-institute-options.yes')} />
                                              </Grid>
                                              <Grid item xs={6}>
                                                <FormControlLabel value='no' control={<Radio onClick={handleSameSchoolChange} disabled={!canEdit} checked={grade === "fifth" ? !fifthGradeSchool.sameSchool : !fourthGradeSchool.sameSchool} />} label={grade === "fifth" ? t('education.fifth-year.fifth-year-institution.same-institute-options.no') : t('education.fourth-year.fourth-year-institution.same-institute-options.no')} />
                                              </Grid>
                                            </Grid>
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                      {(grade === "fifth" ? fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool) ?
                                        <>
                                          <Grid item container spacing={2}>
                                            {
                                              grade === "fifth" ?
                                                <>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                  >
                                                    <FormControl fullWidth>
                                                      <TextField inputProps={{ maxLength: 4 }}
                                                        disabled={!canEdit}
                                                        onKeyDown={handlePreventTypingChars}
                                                        fullWidth
                                                        value={fourthGradeSchool.schoolYear !== 0 ? fourthGradeSchool.schoolYear : ''}
                                                        label={t('education.fifth-year.fifth-year-institution.year-completed-four-high-school')}
                                                        onChange={handleFourthYearChange}
                                                      />
                                                    </FormControl>
                                                    {
                                                      errFourthYear.length ?
                                                        <>
                                                          <Grid item>
                                                            <div className="disclaimer validation-error">{errFourthYear}</div>
                                                          </Grid>
                                                        </> : <></>
                                                    }
                                                  </Grid>
                                                </> : <></>
                                            }
                                            <Grid
                                              item
                                              xs={12}
                                            >
                                              <FormControl fullWidth>
                                                <TextField inputProps={{ maxLength: 4 }}
                                                  disabled={!canEdit}
                                                  onKeyDown={handlePreventTypingChars}
                                                  fullWidth
                                                  value={thirdGradeSchool.schoolYear !== 0 ? thirdGradeSchool.schoolYear : ''}
                                                  label={grade === "fifth" ? t('education.fifth-year.fifth-year-institution.year-completed-three-high-school') : t('education.fourth-year.fourth-year-institution.year-completed-three-high-school')}
                                                  onChange={handleThirdYearChange}
                                                />
                                              </FormControl>
                                              {
                                                errThirdYear.length ?
                                                  <>
                                                    <Grid item>
                                                      <div className="disclaimer validation-error">{errThirdYear}</div>
                                                    </Grid>
                                                  </> : <></>
                                              }
                                            </Grid>
                                          </Grid>
                                        </> : <></>}
                                    </> : <></>
                                }
                              </Grid>
                            </> : <></>
                        }
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <ULButton
                      working={working}
                      onClick={handleContinue}
                      disabled={
                        grade === "fifth" ?
                          (
                            files.length < 1 || !files?.every(file => file.uploaded === true) || (
                              !(fifthGradeSchool.schoolYear.toString().length && fifthGradeSchool.schoolYear > MIN_DATE && fifthGradeSchool.schoolYear <= currentYear)
                              || !(
                                !fifthGradeSchool.sameSchool ||
                                (fifthGradeSchool.sameSchool && fourthGradeSchool.schoolYear.toString().length && thirdGradeSchool.schoolYear.toString().length && fifthGradeSchool.schoolYear > fourthGradeSchool.schoolYear && fourthGradeSchool.schoolYear > thirdGradeSchool.schoolYear && thirdGradeSchool.schoolYear > MIN_DATE)
                              )
                            )
                          ) :
                          grade === "fourth" ?
                            (
                              files.length < 1 || !files?.every(file => file.uploaded === true) || (
                                !(fourthGradeSchool.schoolYear.toString().length === 4 && fourthGradeSchool.schoolYear > MIN_DATE && fourthGradeSchool.schoolYear < fifthGradeSchool.schoolYear) ||
                                !(!fourthGradeSchool.sameSchool || (fourthGradeSchool.sameSchool && thirdGradeSchool.schoolYear.toString().length === 4 && fourthGradeSchool.schoolYear > thirdGradeSchool.schoolYear && thirdGradeSchool.schoolYear > MIN_DATE))
                              )
                            ) :
                            (
                              files.length < 1 || !files?.every(file => file.uploaded === true) || (
                                !thirdGradeSchool.schoolType ||
                                !(thirdGradeSchool.schoolYear.toString().length === 4 && thirdGradeSchool.schoolYear > MIN_DATE && thirdGradeSchool.schoolYear < fourthGradeSchool.schoolYear)
                              )
                            )
                      }
                    >
                      {
                        grade === "fifth" ? t('buttons.continue') :
                          grade === "fourth" ? t('buttons.continue') :
                            t('buttons.continue')
                      }
                    </ULButton>
                  </Grid>
                  <Grid item xs={12}
                  >
                    <ULButton
                      variant="outlined"
                      onClick={
                        () => navigate(
                          grade === "fifth" ? ROUTES.STUDENT_PERSONAL_DATA :
                            grade === "fourth" ? ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE :
                              ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE
                        )
                      }
                      fullWidth
                      goBack={true}
                    >
                      {t('buttons.back')}
                    </ULButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </>
  );
}

export default EducationSchoolNotFound;